import axios from 'axios';

const http = axios.create({
  baseURL: process.env.VUE_APP_URL,
  headers: {
    'Content-type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
  withCredentials: true,
});

const httpFile = axios.create({
  baseURL: process.env.VUE_APP_URL,
  headers: {
    'Access-Control-Allow-Origin': '*',
  },
  withCredentials: true,
});

const interceptResponse = (res) => Promise.resolve(res.data);
const interceptResponseErrors = (error) => Promise.reject(error);

http.interceptors.response.use(interceptResponse, interceptResponseErrors);

const interceptRequest = (request) => request;
const interceptRequestErrors = (err) => Promise.reject(err);

http.interceptors.request.use(interceptRequest, interceptRequestErrors);
export { http, httpFile };
