<script>
import Message from '@/components/Message.vue';
import LoginService from '@/services/LoginService';

export default {
  name: 'LoginView',
  data() {
    return {
      email: null,
      senha: null,
      hasClicked: false,
      loginViewModel: null,
      loginInputModel: null,
      msg: null,
      publicToken: null,
    };
  },
  mounted() {
    if (!((new URLSearchParams(window.location.search)).has('skip'))) {
      const publicToken = this.$cookies.get('PublicToken');
      const hostRedirect = this.$cookies.get('HostRedirect');

      if (publicToken && hostRedirect) { window.location.href = hostRedirect; }
    } else {
      const urlApplication = process.env.VUE_APP_COOKIE_URL;
      const pathApplication = '/';
      this.$cookies.remove('PublicToken', pathApplication, urlApplication);
      this.$cookies.remove('HostRedirect', pathApplication, urlApplication);
    }
  },
  methods: {
    async validarLogin() {
      await LoginService.valid(this.loginInputModel)
        .then((res) => {
          this.loginViewModel = res;
        })
        .catch((error) => {
          if (error.response.status === 406) { window.location.href = '/maintance'; }

          this.msg = error.response.data.Message;
          this.senha = '';
        });
    },
    setCookie() {
      const urlApplication = process.env.VUE_APP_COOKIE_URL;
      const pathApplication = '/';
      const diasValidadeCookie = '6d';
      this.$cookies.set('PublicToken', this.loginViewModel.publicToken, diasValidadeCookie, pathApplication, urlApplication);
      this.$cookies.set('HostRedirect', this.loginViewModel.host, diasValidadeCookie, pathApplication, urlApplication);
    },
    validForm() {
      if (!this.loginInputModel.email) throw new Error('Informe um e-mail.');
      else if (!this.loginInputModel.senha) throw new Error('Informe uma senha.');
      else {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!re.test(this.loginInputModel.email)) throw new Error('E-mail inválido.');
      }
    },
    async login(e) {
      e.preventDefault();
      this.loginInputModel = {
        email: this.email,
        senha: this.senha,
      };

      try {
        this.validForm();
        await this.validarLogin();
        if (this.loginViewModel.publicToken) {
          this.setCookie();
          window.location.href = this.loginViewModel.host;
        }
      } catch (error) {
        this.msg = error.response.data.Message;
      }

      this.loginInputModel = null;
    },
  },
  components: { Message },
};
</script>

<template>
  <main class="main" id="top">
    <div class="container-fluid">
      <div class="row min-vh-100 flex-center g-0">
        <div class="col-lg-8 col-xxl-5 py-3 position-relative">
          <img class="bg-auth-circle-shape" src="@/assets/img/bg-shape.png" alt="" width="250" /><img class="bg-auth-circle-shape-2" src="@/assets/img/shape-1.png" alt="" width="150" />
          <div class="card overflow-hidden z-index-1">
            <div class="card-body p-0">
              <div class="row g-0 h-100">
                <div class="col-md-5 text-center bg-card-gradient">
                  <div class="position-relative p-4 pt-md-5 pb-md-7 light">
                    <div class="bg-holder bg-auth-card-shape" style="background-image: url(/assets/img/half-circle.png)"></div>
                    <div class="z-index-1 position-relative">
                      <span class="link-light mb-4 font-sans-serif fs-4 d-inline-block fw-bolder">E-Trade</span>
                      <p class="opacity-75 text-white">
                        Nossa versão Web é uma extensão na nuvem do E-Trade, para ajuda-lo a
                        melhorar a gerência do seu negócio, trazendo mais produtividade.
                      </p>
                    </div>
                  </div>
                  <div class="mt-3 mb-4 mt-md-4 mb-md-5 light">
                    <p class="mb-0 mt-4 mt-md-5 fs--1 fw-semi-bold text-white opacity-75">
                      Leia nossos
                      <a class="text-decoration-underline text-white" target="_blank" href="https://vrsystem.info/publico/post/termos-e-condicoes-da-vr-system-para-o-e-trad/c410c3ce-c98d-4f34-a455-fea60ff43877">termos
                      </a>
                      e
                      <a class="text-decoration-underline text-white" href="https://vrsystem.info/publico/post/declaracao-de-privacidade/7d39741a-e60d-48d7-b0c8-ddd7ecb9ab47" target="_blank">privacidade</a>
                    </p>
                  </div>
                </div>
                <div class="col-md-7 d-flex flex-center">
                  <div class="p-4 p-md-5 flex-grow-1">
                    <div class="row flex-between-center">
                      <div class="col-auto">
                        <h3>Login</h3>
                      </div>
                    </div>
                    <form id="login-form" @submit="login">
                      <div class="mb-3">
                        <label for="txtEmail" class="form-label">Email</label>
                        <input v-model="email" class="form-control" id="txtEmail" type="text" />
                      </div>
                      <div class="mb-3">
                        <div class="d-flex justify-content-between">
                          <label class="form-label" for="txtSenha">Senha</label>
                          <a href="/password/forgot/">
                            <small>Esqueceu a senha?</small>
                          </a>
                        </div>
                        <input v-model="senha" class="form-control" id="txtSenha" type="password" />
                      </div>
                      <div class="mb-3">
                        <button v-bind:disabled="loginInputModel" class="btn btn-primary d-block w-100 mt-3" type="submit" name="submit">
                          Entrar
                        </button>
                      </div>
                      <Message :msg="msg" v-show="msg" />
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
