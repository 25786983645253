<script setup>
import MessageApp from '@/components/MessageApp.vue';
import LoginService from '@/services/LoginService';
import { props } from '@/store';
import { ref } from 'vue';

const btnEnviar = ref();
const showMessage = ref(false);
const statusMessage = ref();

async function enviarLinkButton() {
  btnEnviar.value.disabled = true;

  await LoginService.forgotPassword(props.value.passwordForgotForm)
    .then(() => {
      showMessage.value = true;
      statusMessage.value.hideMessage();
    })
    .catch((error) => {
      showMessage.value = false;
      btnEnviar.value.disabled = false;
      statusMessage.value.setMessage(error.response.data.Message);
      console.log(error);
    });
}
</script>
<template>
  <div class="container-xxl">
    <div class="authentication-wrapper authentication-basic container-p-y">
      <div class="authentication-inner py-4">
        <div class="card">
          <div class="card-body">
            <div class="app-brand justify-content-center mb-4 mt-2">
              <span class="app-brand-logo demo">
                <img src="/assets/img/logo.png" width="35" alt="E-Trade Web" class="mb-2" />
              </span>
              <span class="app-brand-text demo fw-bold">VR System</span>
            </div>
            <div v-if="showMessage">
              <h4 class="mb-1 pt-2">Verifique o seu e-mail ✉️</h4>
              <p class="text-start mb-4">
                Enviamos um e-mail para: {{ props.passwordForgotForm.email }}<br /><br />Caso não receba, verifique todas as caixas do seu e-mail ou se forneceu o endereço de e-mail correto.
              </p>
            </div>
            <div v-else>
              <MessageApp ref="statusMessage" @hideAlert="statusMessage.hideMessage()" showClose />
              <h4 class="mb-1 pt-2">Esqueceu a senha? 🔒</h4>
              <p class="mb-4">Para redefinir a senha, informe seu e-mail abaixo e iremos enviar instruções para ele.</p>
              <div class="mb-3 fv-plugins-icon-container">
                <label for="email" class="form-label">E-mail</label>
                <input v-model="props.passwordForgotForm.email" type="text" class="form-control" id="email" name="email" placeholder="Entre com seu e-mail de login">
                <div class="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div>
              </div>
              <button ref="btnEnviar" @click="enviarLinkButton" class="btn btn-primary d-grid w-100 waves-effect waves-light">Enviar e-mail para redefinir senha</button>
              <input type="hidden">
              <div class="text-center">
                <a href="/" class="d-flex align-items-center justify-content-center">
                  <i class="ti ti-chevron-left scaleX-n1-rtl"></i>
                  ← Voltar para o login
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.authentication-wrapper {
  display: flex;
  flex-basis: 100%;
  min-height: 100vh;
  width: 100%
}

.authentication-wrapper .authentication-inner {
  width: 100%
}

.authentication-wrapper.authentication-basic {
  align-items: center;
  justify-content: center
}

.authentication-wrapper.authentication-basic .card-body {
  padding: 2rem
}

.authentication-wrapper.authentication-cover {
  align-items: flex-start
}

.authentication-wrapper.authentication-cover .authentication-inner {
  height: 100%;
  margin: auto 0
}

.authentication-wrapper.authentication-cover .authentication-inner .auth-cover-bg {
  width: 100%;
  margin: 2rem 0 2rem 2rem;
  height: calc(100vh - 4rem);
  border-radius: 1.125rem;
  position: relative
}

.authentication-wrapper.authentication-cover .authentication-inner .auth-cover-bg .auth-illustration {
  max-height: 65%;
  z-index: 1
}

.authentication-wrapper.authentication-cover .authentication-inner .platform-bg {
  position: absolute;
  width: 100%;
  bottom: 0%;
  left: 0%;
  height: 35%
}

.authentication-wrapper.authentication-cover .authentication-inner .auth-multisteps-bg-height {
  height: 100vh
}

.authentication-wrapper.authentication-cover .authentication-inner .auth-multisteps-bg-height>img:first-child {
  z-index: 1
}

.authentication-wrapper.authentication-basic .authentication-inner {
  max-width: 400px;
  position: relative
}

.authentication-wrapper.authentication-basic .authentication-inner:before {
  width: 238px;
  height: 233px;
  content: " ";
  position: absolute;
  top: -55px;
  left: -40px;
  background-image: url("data:image/svg+xml,%3Csvg width='239' height='234' viewBox='0 0 239 234' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='88.5605' y='0.700195' width='149' height='149' rx='19.5' stroke='%237367F0' stroke-opacity='0.16'/%3E%3Crect x='0.621094' y='33.761' width='200' height='200' rx='10' fill='%237367F0' fill-opacity='0.08'/%3E%3C/svg%3E%0A")
}

@media (max-width: 575.98px) {
  .authentication-wrapper.authentication-basic .authentication-inner:before {
    display: none
  }
}

.authentication-wrapper.authentication-basic .authentication-inner:after {
  width: 180px;
  height: 180px;
  content: " ";
  position: absolute;
  z-index: -1;
  bottom: -30px;
  right: -56px;
  background-image: url("data:image/svg+xml,%3Csvg width='181' height='181' viewBox='0 0 181 181' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='1.30469' y='1.44312' width='178' height='178' rx='19' stroke='%237367F0' stroke-opacity='0.16' stroke-width='2' stroke-dasharray='8 8'/%3E%3Crect x='22.8047' y='22.9431' width='135' height='135' rx='10' fill='%237367F0' fill-opacity='0.08'/%3E%3C/svg%3E")
}

@media (max-width: 575.98px) {
  .authentication-wrapper.authentication-basic .authentication-inner:after {
    display: none
  }
}

.authentication-wrapper .auth-input-wrapper .auth-input {
  max-width: 50px;
  padding-left: .4rem;
  padding-right: .4rem;
  font-size: 150%
}

.app-brand-logo.demo {
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: -ms-flexbox;
  display: flex;
  width: 34px;
  margin-right: 10px;
}

.app-brand-text.demo {
  font-size: 1.375rem;
}

.app-brand {
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  overflow: hidden;
  line-height: 1;
  min-height: 1px;
  align-items: center
}

.app-brand-link {
  display: flex;
  align-items: center
}

.app-brand-logo {
  display: block;
  flex-grow: 0;
  flex-shrink: 0;
  overflow: hidden;
}

.app-brand-logo img,
.app-brand-logo svg {
  display: block
}

.app-brand-text {
  flex-shrink: 0;
  opacity: 1;
  transition: opacity .3s ease-in-out;
  color: #1B447B;
}
</style>
