import { ref } from 'vue';

const props = ref({
  passwordForgotForm: {
    email: '',
  },
  passwordResetForm: {
    password: '',
    confirmPassword: '',
    key: '',
    token: '',
  },
});

export { props };
