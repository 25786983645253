<script setup>
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { computed, defineEmits, defineExpose, defineProps, inject, ref } from 'vue';

const dayJS = inject('dayJS');
dayjs.extend(duration);

const showMessage = ref(false);
const messageBody = ref('');
const variant = ref('alert-danger');

const props = defineProps({
  showClose: {
    type: Boolean,
    default: false,
  },
  marginTop: {
    type: Number,
    default: 2,
  },
});

defineEmits(['hideAlert']);

function setMessage(message, variantClass = 'danger', timeoutSeconds = 0) {
  messageBody.value = message;
  showMessage.value = true;
  variant.value = variantClass ? `alert-${variantClass}` : 'alert-danger';
  if (timeoutSeconds && timeoutSeconds > 0) {
    setTimeout(() => {
      showMessage.value = false;
    }, dayJS.duration({ second: timeoutSeconds }).asMilliseconds());
  }
}

const marginTopClass = computed({
  get: () => (props.marginTop >= 0 ? `mt-${props.marginTop}` : 'mt-2'),
});

const baseClass = computed({
  get: () => ([
    'alert',
    'alert-dismissible',
    'fade',
    'show',
    marginTopClass.value,
    variant.value,
  ]),
});

function hideMessage() {
  showMessage.value = false;
}

defineExpose({
  hideMessage,
  setMessage,
});
</script>

<template>
  <div :class="baseClass" role="alert" v-show="showMessage">
    <div class="alert-body">
      <slot><span v-html="messageBody"></span></slot>
    </div>
    <button v-show="props.showClose" @click="$emit('hideAlert')" type="button" class="btn-close"></button>
  </div>
</template>
