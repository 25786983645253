import { http } from '@/http-common';

class LoginService {
  async valid(data) {
    return http.post('/publictoken', data);
  }

  async forgotPassword(data) {
    return http.post('/publicToken/password/forgot', data);
  }

  async resetPassword(data) {
    return http.post('/publicToken/password/reset', data);
  }
}

export default new LoginService();
