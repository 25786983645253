import { createRouter, createWebHistory } from 'vue-router';
import LoginView from '../views/LoginView.vue';
import MaintenanceView from '../views/MaintenanceView.vue';
import PasswordForgotView from '../views/PasswordForgot.vue';
import PasswordResetView from '../views/PasswordReset.vue';

const routes = [
  {
    path: '/',
    name: 'login',
    component: LoginView,
  },
  {
    path: '/maintenance',
    name: 'maintance',
    component: MaintenanceView,
  },
  {
    path: '/password/forgot',
    name: 'password-forgot',
    meta: {
      title: 'Redefinir senha',
    },
    component: PasswordForgotView,
  },
  {
    path: '/password/reset/:key/:token',
    name: 'password-reset',
    meta: {
      title: 'Redefinir senha',
    },
    component: PasswordResetView,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
