<script>
</script>

<template>
  <div class="app-content content">
    <div class="content-wrapper">
      <div class="content-body">
        <div class="misc-wrapper p-2 p-sm-3">
          <div class="misc-inner p-2 p-sm-3">
          <div class="w-100 text-center">
            <a href="/" class="brand-logo">
              <img src="/assets/img/logo.png" alt="E-Trade Web" class="mb-2" />
            </a>
          </div>
            <div class="w-100 text-center">
              <h2 class="mb-1">em manutenção... 🛠</h2>
              <p class="mb-3">Desculpe, estamos em manutenção na sua conta nesse momento, em breve iremos liberar seu acesso.</p>
              <div class="col-12">
                <a class="btn btn-primary mb-2 btn-sm-block" href="/">Voltar para login</a>
              </div>
              <img class="img-fluid" src="/assets/img/under-maintenance.svg" alt="Sistema em manutenção"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
