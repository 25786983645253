<script>
export default {
  name: 'MessageComponent',
  props: {
    msg: String,
  },
};
</script>

<template>
    <div class="alert alert-danger">
        {{ msg }}
    </div>
</template>
